import { ReactNode, createContext, useEffect, useRef } from 'react';
import { getCookies, parseQueryString } from '@/utils';

import { generatePayload } from './utils';
import io from 'socket.io-client';

const reservedEvents = ['connecting', 'connect', 'connect_error', 'disconnect', 'ping', 'pong'];

export const socket = (() => {
  const requestQueue = [];
  let queueTimer = null;
  const token = getCookies('token')?.token || parseQueryString()?.token || '';
  const connection = io(window['__config__']['chat-api-url'], {
    transports: ['websocket'],
    withCredentials: true,
    autoConnect: false,
    reconnection: false,
    query: {
      Authorization: `Bearer ${token}`,
      Id: '',
    },
  });
  const resolveQueue = () => {
    for (let i = 0; i < requestQueue.length; i++) {
      const [queueType, queuePayload, queueOverrides] = requestQueue.shift();
      const wrappedPayload = generatePayload(queueType, queuePayload, queueOverrides);
      const stringifiedPayload = JSON.stringify(wrappedPayload);
      connection._emit.apply(socket, [queueType, stringifiedPayload]);
    }
  };
  connection._emit = connection?.emit;
  connection.emit = (type: string, payload: Record<string, unknown>, overrides: Record<string, unknown> = {}): void => {
    if (reservedEvents.includes(type)) return connection._emit.apply(connection, [type, payload]);
    if (!connection.connected) {
      requestQueue.push([type, payload, overrides]);
      clearTimeout(queueTimer);
      queueTimer = setTimeout(resolveQueue, 1000);
      return;
    }
    const wrappedPayload = generatePayload(type, payload, overrides);
    const stringifiedPayload = JSON.stringify(wrappedPayload);
    return connection._emit.apply(socket, [type, stringifiedPayload]);
  };
  return connection;
})();

const SocketContext = createContext(null);
const SocketProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const retries = useRef<number>(0);
  const retryTimerRef = useRef(null);
  useEffect((): (() => void) => {
    socket.on('connect', (): void => {
      console.log('Connected to server.');
    });
    socket.on('disconnect', (reason, details): void => {
      console.log('Disconnected from server.', reason, details);
    });
    socket.on('connect_error', (err: Error): void => {
      console.error(`Chat API Connection Error: ${err?.message || ''}`);
      if (retries.current < 10) {
        retryTimerRef.current = setTimeout(
          () => {
            console.log('Attempting to reconnect to chat server.');
            socket.connect();
          },
          Math.min(5000 * 1.5 ** retries.current, 30000)
        );
        retries.current += 1;
      } else {
        console.error('Failed to reconnect to chat server after 10 attempts.');
      }
    });

    return (): void => {
      clearTimeout(retryTimerRef.current);
      socket.off('disconnect');
      socket.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export { SocketContext, SocketProvider };
